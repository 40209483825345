import React, { Component } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, ValueFormatterParams, SetFilterValuesFuncParams } from 'ag-grid-community';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { DepartmentService } from '../../../services/DepartmentService';
import { AgGridService } from '../../../services/AgGridService';
import { DepartmentSummaryDto } from '../../../data/Department';
import { AgGridPageSizeSelector } from '../../AgGridPageSizeSelector';
import { RouteComponentProps } from 'react-router-dom';

class DepartmentsServerSideDatasource implements IServerSideDatasource {
    getRows = async (params: IServerSideGetRowsParams) => {
        const fopParameters = AgGridService.convertRequest(params.request);
        const result = await DepartmentService.getSummary(fopParameters);

        // we need to change module names and order
        const modulesMap = Object.entries({
            "Dashboard": "Home",
            "User Console": "Links",
            "Daily Operational": "Daily Operational",
            "Custom IFrame": "", // Dynamically assigned
            "Assignments": "Assignments",
            "Training Portal": "Online Training",
            "Log Drills": "Drills",
            "Course Records": "Courses",
            "Credentials": "Credentials",
            "NFIRS": "NFIRS",
            "Personnel": "Personnel",
            "Data Visualization": "Data Visualization",
            "ISO Training": "Reports",
            "Templates": "Templates"
        });

        // go through each department summary
        result.items.map((value, index) => {
            const modAccessArr = value.moduleAccess.split(",");
            let orderedModules: string[] = new Array();

            // actual re-ordering and renaming
            modulesMap.map((value, index) => {
                if(value[0] === "Custom IFrame") {
                    // Module name is dynamic. Instead of "Custom IFrame", use the module name that 
                    // begins with "@"
                    const customIframeModuleName = modAccessArr.find(m => m.startsWith("@") && m.length > 1);
                    if (customIframeModuleName) {
                        orderedModules.push(customIframeModuleName.substring(1));
                    }
                } else {
                    const res = modAccessArr.find(m => m == value[0]);
                    if (res) {
                        orderedModules.push(value[1]);
                    } 
                }
            });

            value.moduleAccess = orderedModules.join(","); // set new access
        });

        params.successCallback(result.items, result.total);
    }
}

type CellRendererProps = {
    context: Departments,
    data: DepartmentSummaryDto,
}

class StatusCellRenderer extends Component<CellRendererProps> {
    render() {
        const isActive = this.props.data.isActive;

        return (
            <span className={`badge ${isActive ? 'badge-success' : 'badge-danger'}`}>
                {isActive ? 'Active' : 'Inactive'}
            </span>
        )
    }
}

class ActionCellRenderer extends Component<CellRendererProps> {
    onEdit = () => {
        (this.props.context as Departments).onEdit(this.props.data);
    }

    render() {
        return (
            <>
                { /* eslint-disable-next-line */ }
                <a onClick={this.onEdit}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                </a>
            </>
        )
    }
}

type Props = RouteComponentProps & {}

type State = {
    datasource: DepartmentsServerSideDatasource,
}

export class Departments extends Component<Props, State> {
    static displayName = Departments.name;
    private _gridApi?: GridApi;

    state: State = {
        datasource: new DepartmentsServerSideDatasource(),
    }

    onGridReady = (e: GridReadyEvent) => {
        this._gridApi = e.api;
        this._gridApi?.setServerSideDatasource(this.state.datasource);
    }

    onEdit = (department: DepartmentSummaryDto) => {
        this.props.history.push(`/admin/department/${department.departmentId}`)
    }

    onAdd = () => {
        this.props.history.push("/admin/department")
    }

    onPageSizeChange = (pageSize: number) => {
        this._gridApi?.paginationSetPageSize(pageSize);
    }

    loadDepartmentValues = async (params: SetFilterValuesFuncParams) => {
        const items = await DepartmentService.getAllForFilter();
        params.success(items);
    }

    formatIsActive = (params: ValueFormatterParams) => {
        const value = params.value === 'true' ? 'Active' : 'Inactive';
        return value;
    }

    formatDate = (params: ValueFormatterParams) => {
        const value = params.value ? moment(params.value).format('MM/DD/YYYY h:mm A') : '';
        return value;
    }

    render() {
        return (
            <div className="container-fluid h-full">
                <div className="row">
                    <div className="col">
                        <div className="page-title-box">
                            <h4 className="mb-0 font-size-18">DEPARTMENTS</h4>
                        </div>
                    </div>
                </div>
                <div className="row h-full">
                    <div className="col">
                        <div className="card h-full">
                            <div className="card-body h-full flex flex-col">
                                <div className="mb-3 flex-1 align-items-center justify-content-between">
                                    <div>
                                        <button className="btn btn-primary" onClick={this.onAdd}>
                                            Add New Department
                                        </button>
                                    </div>
                                </div>
                                <div className="ag-theme-alpine h-full flex-initial">
                                    <AgGridReact
                                        onGridReady={this.onGridReady}
                                        rowModelType="serverSide"
                                        pagination={true}
                                        paginationPageSize={100}
                                        defaultColDef={{
                                            sortable: true,
                                            menuTabs: ['filterMenuTab'],
                                            filterParams: { buttons: ['reset'] },
                                        }}
                                        enableCellTextSelection={true}
                                        suppressCellSelection={true}
                                        suppressContextMenu={true}
                                        context={this}
                                        frameworkComponents={{
                                            statusCellRenderer: StatusCellRenderer,
                                            actionCellRenderer: ActionCellRenderer,
                                        }}
                                        onRowDoubleClicked={e=> {
                                            this.props.history.push(`/admin/department/${e.data.departmentId}`);                                            
                                        }}
                                    >
                                        <AgGridColumn
                                            headerName="ID"
                                            field="departmentId"
                                            suppressMenu={true}
                                            flex={0} />
                                        <AgGridColumn
                                            headerName="Department"
                                            field="name"
                                            filter="agSetColumnFilter"
                                            filterParams={{ values: this.loadDepartmentValues }}
                                            minWidth={125}
                                            flex={1} />
                                        <AgGridColumn
                                            menuTabs={[]}
                                            headerName="Access"
                                            field="moduleAccess"
                                            sortable={false}
                                            minWidth={150}
                                            flex={1} />
                                        <AgGridColumn
                                            headerName="Last Modified"
                                            field="lastModified"
                                            filter="agDateColumnFilter"
                                            filterParams={{ filterOptions: AgGridService.defaultDateFilterOptions }}
                                            valueFormatter={this.formatDate}
                                            width={180} />
                                        <AgGridColumn
                                            headerName="Status"
                                            field="isActive"
                                            filter="agSetColumnFilter"
                                            filterParams={{ values: [true, false], valueFormatter: this.formatIsActive }}
                                            cellRenderer="statusCellRenderer"
                                            width={100} />
                                        <AgGridColumn
                                            headerName="Action"
                                            cellRenderer="actionCellRenderer"
                                            sortable={false}
                                            width={100}
                                            menuTabs={[]} />
                                    </AgGridReact>
                                </div>
                                <AgGridPageSizeSelector onPageSizeChange={this.onPageSizeChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}