import React, { Component } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, ValueFormatterParams, SetFilterValuesFuncParams } from 'ag-grid-community';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { GroupService } from '../../services/GroupService';
import { AgGridService } from '../../services/AgGridService';
import { GroupSummaryDto, GroupSystemGeneratedEnum } from '../../data/Group';
import { AgGridPageSizeSelector } from '../AgGridPageSizeSelector';
import { RouteComponentProps } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import ApsModels from '../../aps2/models';

class GroupsServerSideDatasource implements IServerSideDatasource {

    status = ApsModels.ActiveEnum.Active;
    generatedBy = GroupSystemGeneratedEnum.UserCreated;

    getRows = async (params: IServerSideGetRowsParams) => {
        const fopParameters = AgGridService.convertRequest(params.request);
        const result = await GroupService.getSummary(fopParameters, this.status, this.generatedBy);
        params.successCallback(result.items, result.total);
    }
}

type CellRendererProps = {
    context: Groups,
    data: GroupSummaryDto,
}

class StatusCellRenderer extends Component<CellRendererProps> {
    render() {
        const isActive = this.props.data.isActive;

        return (
            <span className={`badge ${isActive ? 'badge-success' : 'badge-danger'}`}>
                {isActive ? 'Active' : 'Inactive'}
            </span>
        )
    }
}

class ActionCellRenderer extends Component<CellRendererProps> {
    onEdit = () => {
        (this.props.context as Groups).onEdit(this.props.data);
    }

    render() {
        return (
            <>
                {!this.props.data.isNotEditable && 
                <a onClick={this.onEdit}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                </a>}
            </>
        )
    }
}

type Props = RouteComponentProps & {}

type State = {
    datasource: GroupsServerSideDatasource,
    status: ApsModels.ActiveEnum,
    generatedBy: GroupSystemGeneratedEnum
}

export class Groups extends Component<Props, State> {
    static displayName = Groups.name;
    private _gridApi?: GridApi;

    state: State = {
        datasource: new GroupsServerSideDatasource(),
        status: ApsModels.ActiveEnum.Active,
        generatedBy: GroupSystemGeneratedEnum.UserCreated
    }

    onGridReady = (e: GridReadyEvent) => {
        this._gridApi = e.api;
        this._gridApi?.setServerSideDatasource(this.state.datasource);
    }

    onEdit = (group: GroupSummaryDto) => {
        this.props.history.push(`/admin/group/${group.groupId}`);
    }

    onAdd = () => {
        this.props.history.push("/admin/group");
    }

    onPageSizeChange = (pageSize: number) => {
        this._gridApi?.paginationSetPageSize(pageSize);
    }

    loadGroupValues = async (params: SetFilterValuesFuncParams) => {
        const items = await GroupService.getAllForFilter();
        params.success(items);
    }

    formatIsActive = (params: ValueFormatterParams) => {
        const value = params.value === 'true' ? 'Active' : 'Inactive';
        return value;
    }

    formatDate = (params: ValueFormatterParams) => {
        const value = params.value ? moment(params.value).format('MM/DD/YYYY h:mm A') : '';
        return value;
    }

    setStatus = (status: ApsModels.ActiveEnum) => {
        this.setState({ status: status });                                                                                                               
        this.state.datasource.status = status;
        this._gridApi?.onFilterChanged();
    }

    setGeneratedBy = (generatedBy: GroupSystemGeneratedEnum) => {
        this.setState({ generatedBy: generatedBy });                                                                                                               
        this.state.datasource.generatedBy = generatedBy;
        this._gridApi?.onFilterChanged();
    }

    render() {
        return (
            <div className="container-fluid h-full">
                <div className="row">
                    <div className="col">
                        <div className="page-title-box">
                            <h4 className="mb-0 font-size-18">GROUPS</h4>
                        </div>
                    </div>
                </div>
                <div className="row h-full">
                    <div className="col">
                        <div className="card h-full">
                            <div className="card-body h-full flex flex-col">
                                <div className="align-items-center justify-content-between flex-1">
                                    <div className="flex flex-row flex-wrap">
                                        <div className="flex-1 flex flex-row flex-center mb-3">
                                            <div className="me-2">
                                                <strong>View</strong>
                                            </div>
                                            <Dropdown drop="down" className="me-2">
                                                <Dropdown.Toggle variant="secondary" id="dropdownCourseViewFilter">                                                
                                                    <span className="me-1">{["All", "Active", "Inactive"][this.state.status]}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="left">
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.All}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.All)} >
                                                        All
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.Active}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.Active)} >
                                                        Active
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.Inactive}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.Inactive)} >
                                                        Inactive
                                                    </Dropdown.Item>                                                
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown drop="down">
                                                <Dropdown.Toggle variant="secondary" id="dropdownCourseViewFilter">                                                
                                                    <span className="me-1">{["All Groups", "System Groups", "Agency Groups"][this.state.generatedBy]}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="left">
                                                    <Dropdown.Item 
                                                        disabled={this.state.generatedBy === GroupSystemGeneratedEnum.All}
                                                        onClick={(e) => this.setGeneratedBy(GroupSystemGeneratedEnum.All)} >
                                                        All Groups
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.generatedBy === GroupSystemGeneratedEnum.UserCreated}
                                                        onClick={(e) => this.setGeneratedBy(GroupSystemGeneratedEnum.UserCreated)} >
                                                        Agency Groups
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.generatedBy === GroupSystemGeneratedEnum.SystemGenerated}
                                                        onClick={(e) => this.setGeneratedBy(GroupSystemGeneratedEnum.SystemGenerated)} >
                                                        System Groups
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-primary no-wrap" onClick={this.onAdd}>
                                                Add New Group
                                            </button>      
                                        </div>                                                                                                                      
                                    </div>
                                </div>
                                <div className="ag-theme-alpine h-full flex-initial">
                                    <AgGridReact
                                        onGridReady={this.onGridReady}
                                        rowModelType="serverSide"
                                        pagination={true}
                                        paginationPageSize={100}
                                        defaultColDef={{
                                            sortable: true,
                                            menuTabs: ['filterMenuTab'],
                                            filterParams: { buttons: ['reset'] },
                                        }}
                                        enableCellTextSelection={true}
                                        suppressCellSelection={true}
                                        suppressContextMenu={true}
                                        context={this}
                                        frameworkComponents={{
                                            statusCellRenderer: StatusCellRenderer,
                                            actionCellRenderer: ActionCellRenderer,
                                        }}
                                    >
                                        <AgGridColumn
                                            headerName="Group"
                                            field="name"
                                            filter="agSetColumnFilter"
                                            filterParams={{ values: this.loadGroupValues }}
                                            minWidth={125}
                                            flex={1} />
                                        <AgGridColumn
                                            headerName="Last Modified"
                                            field="lastModified"
                                            filter="agDateColumnFilter"
                                            filterParams={{ filterOptions: AgGridService.defaultDateFilterOptions }}
                                            valueFormatter={this.formatDate}
                                            width={180} />
                                        <AgGridColumn
                                            headerName="Status"
                                            field="isActive"
                                            filter="agSetColumnFilter"
                                            //filterParams={{ values: [true, false], valueFormatter: this.formatIsActive }}
                                            suppressMenu={true}
                                            cellRenderer="statusCellRenderer"
                                            width={100} />
                                        <AgGridColumn
                                            headerName="Action"
                                            cellRenderer="actionCellRenderer"
                                            sortable={false}
                                            width={100}
                                            menuTabs={[]} />
                                    </AgGridReact>
                                </div>
                                <AgGridPageSizeSelector onPageSizeChange={this.onPageSizeChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}