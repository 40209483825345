import React, { Component } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { GridApi, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams, ValueFormatterParams, SetFilterValuesFuncParams } from 'ag-grid-community';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services/UserService';
import { AgGridService } from '../../services/AgGridService';
import { UserSummaryDto } from '../../data/User';
import { AgGridPageSizeSelector } from '../AgGridPageSizeSelector';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Unsubscriber } from '../../common/Observable';
import {Dropdown} from "react-bootstrap";
import UserRecordImportDialog from "./UserRecordImportDialog";
import commonService from '../../aps2/services/CommonService';
import FileSaver from 'file-saver';
import toastStore from '../../aps2/stores/ToastStore';
import ApsServices from '../../aps2/services';
import ApsModels from '../../aps2/models';

var currentDepartmentName: string | undefined;

class UsersServerSideDatasource implements IServerSideDatasource {
    status = ApsModels.ActiveEnum.Active;
    getRows = async (params: IServerSideGetRowsParams) => {
        // if no name is set then the user sees nothing, else they see their department users
        var filterValues: any;
        if (currentDepartmentName == undefined || currentDepartmentName == "")
            filterValues = [];
        else
            filterValues = [currentDepartmentName];

        // intercept and set department filter
        const fopParameters = AgGridService.convertRequest(params.request);
        fopParameters.filter.push({
            field: "department",
            filterType: "set",
            operator: "in",
            values: filterValues,
        })
        const result = await UserService.getSummary(fopParameters, this.status);
        params.successCallback(result.items, result.total);
    }
}

type CellRendererProps = {
    context: Users,
    data: UserSummaryDto,
}

class StatusCellRenderer extends Component<CellRendererProps> {
    render() {
        const isActive = this.props.data.isActive;

        return (
            <span className={`badge ${isActive ? 'badge-success' : 'badge-danger'}`}>
                {isActive ? 'Active' : 'Inactive'}
            </span>
        )
    }
}

class ActionCellRenderer extends Component<CellRendererProps> {
    private displayName = window.location.pathname.split('/').pop();

    onEdit = () => {
        (this.props.context as Users).onEdit(this.props.data);
    }

    render() {
        var link = this.displayName?.toLowerCase() === "personnel" ? `/personnel/${this.props.data.userId}` : `/admin/user/${this.props.data.userId}`

        return (
            <Link to={link}>
                { /* eslint-disable-next-line */ }
                <a onClick={this.onEdit}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                </a>
            </Link>
        )
    }
}

type Props = RouteComponentProps & {}

type State = {
    datasource: UsersServerSideDatasource,
    showImport: boolean,
    exporting: boolean,    
    status: ApsModels.ActiveEnum,
}

export class Users extends Component<Props, State> {
    private _gridApi?: GridApi;
    private _currentDepartmentUnsubscriber: Unsubscriber | null = null;
    private displayName = window.location.pathname.split('/').pop();
    private isPersonnel = window.location.pathname.split('/').pop()?.toLowerCase()?.trim() === "personnel";

    componentDidMount() {
        currentDepartmentName = UserService.currentDepartmentName.get();
        this._currentDepartmentUnsubscriber = UserService.currentDepartmentName.subscribe(x => {
            currentDepartmentName = x;
            this._gridApi?.purgeServerSideCache();
        });
    }

    componentWillUnmount() {
        this._currentDepartmentUnsubscriber?.();
    }

    state: State = {
        datasource: new UsersServerSideDatasource(),
        showImport: false,
        exporting: false,        
        status: ApsModels.ActiveEnum.Active,
    }

    onGridReady = (e: GridReadyEvent) => {
        this._gridApi = e.api;
        this._gridApi?.setServerSideDatasource(this.state.datasource);
    }

    onEdit = (user: UserSummaryDto) => {
    }

    onPageSizeChange = (pageSize: number) => {
        this._gridApi?.paginationSetPageSize(pageSize);
    }

    formatIsActive = (params: ValueFormatterParams) => {
        const value = params.value === 'true' ? 'Active' : 'Inactive';
        return value;
    }

    formatDate = (params: ValueFormatterParams) => {
        const value = params.value ? moment(params.value).format('MM/DD/YYYY h:mm A') : '';
        return value;
    }
    
    startExport = async () => {
        this.setState({exporting: true})
        await ApsServices.http.user
            .exportUsers()
            .then((data) => {
                const file = commonService.b64toBlob(data.fileContents, "text/csv");
                FileSaver.saveAs(file, data.fileDownloadName);
            })
            .catch((err) => {
                toastStore.showError("Failed exporting users", err);
            }).finally(() => {
                this.setState({exporting: false})
            });
    };

    setStatus = (status: ApsModels.ActiveEnum) => {
        this.setState({ status: status });                                                                                                               
        this.state.datasource.status = status;
        this._gridApi?.onFilterChanged();
    }

    render() {
        return (
            <>
                {this.state.showImport && 
                    <UserRecordImportDialog onClose={(e) => this.setState({showImport: false})} />
                }
                <div className="container-fluid h-full" id="users-table">
                    <div className="row">
                        <div className="col">
                            <div className="page-title-box">
                                <h4 className="mb-0 font-size-18">{this.displayName}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row h-full">
                        <div className="col">
                            <div className="card h-full">
                                <div className="card-body h-full flex-col flex">
                                    <div className="mb-3 flex flex-row justify-content-between">
                                        <div className="flex flex-row flex-center">
                                            <Dropdown drop="down" className="me-2">
                                                <Dropdown.Toggle
                                                    variant="outline-secondary"
                                                    id="dropdownTeamsFilter"
                                                    // disabled={loading}
                                                >
                                                    {this.state.exporting ? "Exporting..." : "Export or Import"}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={(e) => {
                                                            this.startExport();
                                                        }}
                                                        disabled={this.state.exporting}
                                                    >
                                                        Export All
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={(e) => {
                                                            this.setState({showImport: true});
                                                        }}
                                                    >
                                                        Import CSV
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <div className="mx-2">
                                                <strong>View</strong>
                                            </div>                                            
                                            <Dropdown drop="down">
                                                <Dropdown.Toggle variant="secondary" id="dropdownCourseViewFilter">                                                    
                                                    <span className="me-1">{["All", "Active", "Inactive"][this.state.status]}</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu align="left">
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.All}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.All)} >
                                                        All
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.Active}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.Active)} >
                                                        Active
                                                    </Dropdown.Item>
                                                    <Dropdown.Item 
                                                        disabled={this.state.status === ApsModels.ActiveEnum.Inactive}
                                                        onClick={(e) => this.setStatus(ApsModels.ActiveEnum.Inactive)} >
                                                        Inactive
                                                    </Dropdown.Item>                                                
                                                </Dropdown.Menu>
                                            </Dropdown>                                            
                                        </div>
                                        <div>
                                            <Link to={this.isPersonnel ? "/personnel/add" : "/admin/user"}>
                                                <button className="btn btn-primary">
                                                    Add New User
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="ag-theme-alpine h-full flex-initial">
                                        <AgGridReact
                                            onGridReady={this.onGridReady}
                                            rowModelType="serverSide"
                                            pagination={true}
                                            paginationPageSize={100}
                                            defaultColDef={{
                                                sortable: true,
                                                menuTabs: ['filterMenuTab'],
                                                filterParams: {buttons: ['reset']},
                                            }}
                                            enableCellTextSelection={true}
                                            suppressCellSelection={true}
                                            suppressContextMenu={true}
                                            context={this}
                                            frameworkComponents={{
                                                statusCellRenderer: StatusCellRenderer,
                                                actionCellRenderer: ActionCellRenderer,
                                            }}
                                            onRowDoubleClicked={(event) => {
                                                var link = this.displayName?.toLowerCase() === "personnel" ? `/personnel/${event.data.userId}` : `/admin/user/${event.data.userId}`;                                                
                                                this.props.history.push(link);
                                            }}
                                        >
                                            <AgGridColumn
                                                headerName="User ID"
                                                field="userId"
                                                filter="agNumberColumnFilter"
                                                width={125}/>
                                            <AgGridColumn
                                                headerName="First Name"
                                                field="firstName"
                                                filter="agTextColumnFilter"
                                                minWidth={150}
                                                flex={1}/>
                                            <AgGridColumn
                                                headerName="Last Name"
                                                field="lastName"
                                                filter="agTextColumnFilter"
                                                minWidth={150}
                                                flex={1}/>
                                            <AgGridColumn
                                                headerName="Department"
                                                field="department"
                                                suppressMenu={true}
                                                minWidth={150}
                                                flex={1}/>
                                            <AgGridColumn
                                                headerName="Email"
                                                field="email"
                                                filter="agTextColumnFilter"
                                                minWidth={350}
                                                flex={1}/>
                                            <AgGridColumn
                                                headerName="Last Logged In"
                                                field="lastLoggedIn"
                                                filter="agDateColumnFilter"
                                                filterParams={{filterOptions: AgGridService.defaultDateFilterOptions}}
                                                valueFormatter={this.formatDate}
                                                width={180}/>
                                            <AgGridColumn
                                                headerName="Status"
                                                field="isActive"
                                                filter="agSetColumnFilter"
                                                // filterParams={{
                                                //     values: [true, false],
                                                //     valueFormatter: this.formatIsActive
                                                // }}
                                                suppressMenu={true}
                                                cellRenderer="statusCellRenderer"
                                                width={100}/>
                                            <AgGridColumn
                                                headerName="Action"
                                                cellRenderer="actionCellRenderer"
                                                sortable={false}
                                                width={100}
                                                menuTabs={[]}/>
                                        </AgGridReact>
                                    </div>
                                    <AgGridPageSizeSelector onPageSizeChange={this.onPageSizeChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}