import React, { useEffect, useState } from "react";
import ApsModels from "../../../models";
import ApsServices from "../../../services";
import systemStore from "../../../stores/SystemStore";
import CommonSpinner from "../../Common/CommonSpinner";
import { FgSelect, FgUseForm } from "../../Common/FormGroups";
import DTCompanyShift from "./DTCompanyShift";
import DTDetail from "./DTDetail";
import DTExternalPartners from "./DTExternalPartners";
import "../../../styles/common.scss";
import toastStore from "../../../stores/ToastStore";
import commonService from "../../../services/CommonService";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { FgInput } from "../../Common/FormGroups/FgInput";

function DrillTemplate(props: {
  id?: any;
  hasChanges?: (hasChanges: boolean) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [drillTemplate, setDrillTemplate] = useState(
    {} as ApsModels.IDrillTemplateOutputDto
  );
  const [originalDrillTemplate, setOriginalDrillTemplate] = useState(
    {} as ApsModels.IDrillTemplateOutputDto
  );
  const [changedDrillTemplate, setChangedDrillTemplate] = useState(
    {} as ApsModels.IDrillTemplateOutputDto
  );
  const days: number[] = [];
  let cnt = 1;
  while (cnt < 31) {
    days.push(cnt);
    cnt++;
  }
  days.push(366);

  const { handleSubmit, formState, registry, setValue, getValues } = FgUseForm({
    jacAgencyId: {
      displayName: "JAC Agency ID",
      validation: { required: false, maxLength: 64 },
    },
    numDaysReportDraftCanBeKept: {
      displayName: "Number of Days",
      validation: { required: true },
    },
    numDaysSubmittedReportCanBeEdited: {
      displayName: "Number of Days",
      validation: { required: true },
    },
    numDaysToSubmitDrill: {
      displayName: "Number of Days",
      validation: { required: true },
    },
    useDurationYesNo: {
      displayName: "Duration Selection Mode",
      validation: { required: false },
    },
  });

  const getDrillTemplate = async (wasAfterSave = false) => {
    if (wasAfterSave) {
      setSaving(true);
    } else {
      setLoading(true);
    }
    await ApsServices.http.drillTemplate
      .get(systemStore.currentDrillTemplateDepartment)
      .then((data) => {
        if (!data.numDaysToSubmitDrill) {
          data.numDaysToSubmitDrill = 6; //default
        }
        setDrillTemplate({ ...data });
        setChangedDrillTemplate({ ...data });
        setOriginalDrillTemplate(commonService.deepCloneJsonObject(data || {}));
        setLoading(false);
        setSaving(false);
        setValue("jacAgencyId", data.jacAgencyId);
        setValue(
          "numDaysReportDraftCanBeKept",
          data.numDaysReportDraftCanBeKept
        );
        setValue(
          "numDaysSubmittedReportCanBeEdited",
          data.numDaysSubmittedReportCanBeEdited
        );
        setValue("numDaysToSubmitDrill", data.numDaysToSubmitDrill);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setSaving(false);
        toastStore.showError("Error Geting Log Drill Template", err);
      });
  };

  const [err409, setErr409] = useState(false);
  const [saving, setSaving] = useState(false);
  const submit = (form: any) => {
    setSaving(true);
    ApsServices.http.drillTemplate
      .save({
        ...changedDrillTemplate,
        isaCourses: changedDrillTemplate.isaCourses.map((c) => {
          return {
            ...c,
            id: c.id < 0 ? null : c.id,
          };
        }),
        drills: changedDrillTemplate.drills.map((c) => {
          return {
            ...c,
            id: c.id < 0 ? null : c.id,
          };
        }),
      } as any)
      .then((data) => {
        getDrillTemplate(true);
        toastStore.showToast("Drill Template saved successfully.", "success");
      })
      .catch((err) => {
        console.log(err);
        if (commonService.getErrorStatusCode(err) === 409) {
          setErr409(true);
        } else {
          toastStore.showError("Error saving Drill Template", err);
        }
        setSaving(false);
      });
  };

  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    const id = parseInt(props?.id) || 0;
    if (id && !collapse && !drillTemplate?.departmentId) {
      systemStore.setCurrentDrillTemplateDepartment(id);
      getDrillTemplate();
    }
  }, [collapse]);

  const [hasChanges, setHasChanges] = useState(false);
  useEffect(() => {
    if (changedDrillTemplate && originalDrillTemplate) {
      let changesFound = false;
      for (let p in originalDrillTemplate) {
        if (!changesFound) {
          const isArray1 = Array.isArray((originalDrillTemplate as any)[p]);
          const isArray2 = Array.isArray((changedDrillTemplate as any)[p]);
          if (isArray1 || isArray2) {
            if (!isArray1 || !isArray2) {
              changesFound = true;
            } else {
              const arr1 = (originalDrillTemplate as any)[p] as any[];
              const arr2 = (changedDrillTemplate as any)[p] as any[];
              if (arr1.length === arr2.length) {
                arr1.forEach((a1) => {
                  if (!changesFound) {
                    changesFound =
                      arr2.filter(
                        (a2) =>
                          JSON.stringify(a2 || {}) === JSON.stringify(a1 || {})
                      ).length === 0;
                  }
                });
              } else {
                changesFound = true;
              }
            }
          } else {
            changesFound =
              (originalDrillTemplate as any)[p] !==
              (changedDrillTemplate as any)[p];
          }
        }
      }
      //console.log(`hasChanges: ${hasChanges}`);
      if (changesFound !== hasChanges) {
        setHasChanges(changesFound);
        if (props.hasChanges) {
          props.hasChanges(changesFound);
        }
      }
    }
  }, [changedDrillTemplate, originalDrillTemplate]);

  return (
    <form onSubmit={handleSubmit(submit)} className="flex flex-1 flex-col">
      <ConfirmDialog
        show={err409}
        buttons="okonly"
        title={commonService.getConcurrencyTitle(409)}
        message={commonService.getConcurrencyMessage(409)}
        done={(rtn) => {
          getDrillTemplate();
          setErr409(false);
        }}
      />

      <div className="container-fluid flex-card-container">
        <div className="flex-0">
          <div className="headerControls">
            <div>
              <span className="pointer" onClick={() => setCollapse(!collapse)}>
                <i
                  className={`fa fa-chevron-${
                    collapse ? "up" : "down"
                  } pl-2 pr-2`}
                ></i>
                <h4 className="pt-3 pl-2 m-0 inline-block">
                  Drills
                  {hasChanges && <span style={{ color: "red" }}>*</span>}
                </h4>
              </span>
            </div>
            <div></div>
          </div>
        </div>

        {loading && (
          <div className="p-1 pb-3">
            <CommonSpinner />
          </div>
        )}
        {!loading &&
          (!parseInt(props?.id) ||
            (parseInt(props?.id) && drillTemplate?.departmentId)) && (
            <>
              <div className={`row flex-1 ${collapse ? "display-none" : ""}`}>
                <div className="col-12 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <strong>General</strong>
                      <div className="mt-4"></div>
                      <div className="row">
                        <div className="col-12 col-xl-4 flex flex-col">
                          <FgInput
                            id="jacAgencyId"
                            label="JAC Agency ID"
                            placeHolder="JAC Agency ID"
                            value={changedDrillTemplate?.jacAgencyId}
                            onChange={(data) => {
                              setChangedDrillTemplate({
                                ...changedDrillTemplate,
                                jacAgencyId: data,
                              });
                            }}
                            registeredField={registry.jacAgencyId}
                            formState={formState}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-4 flex flex-col">
                          <FgSelect
                            id="numDaysReportDraftCanBeKept"
                            label="No. of Days to keep Drafts"
                            options={days.map((c) => {
                              return {
                                label: c.toString(),
                                value: c,
                              };
                            })}
                            disabledOptionValues={days.filter((num) => {
                              const num1 =
                                changedDrillTemplate.numDaysSubmittedReportCanBeEdited;
                              const num2 =
                                changedDrillTemplate.numDaysToSubmitDrill;
                              return !(num >= num1 && num >= num2);
                            })}
                            value={
                              changedDrillTemplate?.numDaysReportDraftCanBeKept
                            }
                            onChange={(data) => {
                              setChangedDrillTemplate({
                                ...changedDrillTemplate,
                                numDaysReportDraftCanBeKept: parseInt(data),
                              });
                            }}
                            registeredField={
                              registry.numDaysReportDraftCanBeKept
                            }
                            formState={formState}
                          />
                        </div>
                        <div className="col-12 col-xl-4 flex flex-col">
                          <FgSelect
                            id="numDaysSubmittedReportCanBeEdited"
                            label="No. of Days Submitted Report can be edited"
                            options={days.map((c) => {
                              return {
                                label: c.toString(),
                                value: c,
                              };
                            })}
                            disabledOptionValues={days.filter((num) => {
                              const num1 =
                                changedDrillTemplate.numDaysToSubmitDrill;
                              return num > num1;
                            })}
                            value={
                              changedDrillTemplate?.numDaysSubmittedReportCanBeEdited
                            }
                            onChange={(data) => {
                              setChangedDrillTemplate({
                                ...changedDrillTemplate,
                                numDaysSubmittedReportCanBeEdited:
                                  parseInt(data),
                              });
                            }}
                            registeredField={
                              registry.numDaysSubmittedReportCanBeEdited
                            }
                            formState={formState}
                          />
                        </div>
                        <div className="col-12 col-xl-4 flex flex-col">
                          <FgSelect
                            id="numDaysToSubmitDrill"
                            label="No. of Days to Submit a Drill"
                            options={days.map((c) => {
                              return {
                                label: c.toString(),
                                value: c,
                              };
                            })}
                            value={changedDrillTemplate?.numDaysToSubmitDrill}
                            onChange={(data) => {
                              const newNum = parseInt(data);
                              let numDraft =
                                changedDrillTemplate.numDaysReportDraftCanBeKept ||
                                0;
                              let numEdit =
                                changedDrillTemplate.numDaysSubmittedReportCanBeEdited ||
                                0;

                              if (numDraft < newNum) {
                                numDraft = newNum;
                                setValue(
                                  "numDaysReportDraftCanBeKept",
                                  numDraft
                                );
                              }
                              if (numEdit > newNum) {
                                numEdit = newNum;
                                setValue(
                                  "numDaysSubmittedReportCanBeEdited",
                                  numEdit
                                );
                              }

                              setChangedDrillTemplate({
                                ...changedDrillTemplate,
                                numDaysToSubmitDrill: newNum,
                                numDaysReportDraftCanBeKept: numDraft,
                                numDaysSubmittedReportCanBeEdited: numEdit,
                              });
                            }}
                            registeredField={registry.numDaysToSubmitDrill}
                            formState={formState}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-4 flex flex-col">
                          <label>Duration Selection Mode</label>

                          <div
                            style={{
                              border: "1px solid rgb(221, 221, 221)",
                              padding: "10px 8px",
                            }}
                          >
                            <div className="flex flex-row flex-center">
                              <input
                                type="radio"
                                name="useDurationYesNo"
                                id="useDurationYesNo1"
                                value="1"
                                checked={!changedDrillTemplate.useDurationYesNo}
                                onChange={(event) => {
                                  setChangedDrillTemplate((prev) => {
                                    return {
                                      ...prev,
                                      useDurationYesNo: false,
                                    };
                                  });
                                }}
                              ></input>
                              <label
                                className="m-0 mx-2 pointer"
                                htmlFor="useDurationYesNo1"
                              >
                                Use Start Time/End Time
                              </label>
                            </div>
                            <div className="flex flex-row flex-center">
                              <input
                                type="radio"
                                name="useDurationYesNo"
                                id="useDurationYesNo2"
                                value="0"
                                checked={changedDrillTemplate.useDurationYesNo}
                                onChange={(event) => {
                                  setChangedDrillTemplate((prev) => {
                                    return {
                                      ...prev,
                                      useDurationYesNo: true,
                                    };
                                  });
                                }}
                              ></input>
                              <label
                                className="m-0 mx-2 pointer"
                                htmlFor="useDurationYesNo2"
                              >
                                Use Duration
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-4 flex flex-col">
                  <DTCompanyShift
                    onChange={(data) => {
                      setChangedDrillTemplate({
                        ...changedDrillTemplate,
                        isCompanyEnabled: data.isCompanyEnabled,
                        companies: data.companies,
                        isShiftEnabled: data.isShiftEnabled,
                        logDrillShifts: data.logDrillShifts,
                      });
                    }}
                    data={drillTemplate}
                  />
                </div>
                <div className="col-12 col-xl-4 flex flex-col">
                  <DTDetail
                    onChange={(data) => {
                      setChangedDrillTemplate({
                        ...changedDrillTemplate,
                        isDrillEnabled: data.isDrillEnabled,
                        drills: data.drills,
                        isLocationEnabled: data.isLocationEnabled,
                        locations: data.locations,
                        isTrainingTypeEnabled: data.isTrainingTypeEnabled,
                        isTrainingTypeMultiSelectEnabled:
                          data.isTrainingTypeMultiSelectEnabled,
                        trainingTypes: data.trainingTypes,
                        isJacEnabled: data.isJacEnabled,
                        isIsaEnabled: data.isIsaEnabled,
                      });
                    }}
                    data={drillTemplate}
                  />
                </div>
                {drillTemplate.isDrillEnabled && (
                  <div className="col-12 col-xl-4 flex flex-col">
                    <DTExternalPartners
                      onChange={(data) => {
                        setChangedDrillTemplate({
                          ...changedDrillTemplate,
                          drills: data.drills,
                          isaCourses: data.isaCourses,
                          isJacEnabled: data.isJacEnabled,
                          isIsaEnabled: data.isIsaEnabled,
                        });
                      }}
                      data={drillTemplate}
                      formState={formState}
                      form={{}}
                    />
                  </div>
                )}
                {!drillTemplate.isDrillEnabled && (
                  <div className="col-12 col-xl-4 flex flex-col">
                    <div className="card">
                      <div className="card-body"></div>
                    </div>
                  </div>
                )}
              </div>

              <div className={`flex-0 ${collapse ? "display-none" : ""}`}>
                <div className="headerControls">
                  <div></div>
                  <div>
                    {parseInt(props?.id) && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        disabled={!hasChanges || saving}
                        onClick={() => {
                          const orig = commonService.deepCloneJsonObject(
                            originalDrillTemplate || {}
                          );
                          setDrillTemplate({ ...orig });
                          setChangedDrillTemplate({ ...orig });
                          setValue(
                            "numDaysReportDraftCanBeKept",
                            orig.numDaysReportDraftCanBeKept
                          );
                          setValue(
                            "numDaysSubmittedReportCanBeEdited",
                            orig.numDaysSubmittedReportCanBeEdited
                          );
                          setValue(
                            "numDaysToSubmitDrill",
                            orig.numDaysToSubmitDrill
                          );
                        }}
                      >
                        Reset
                      </button>
                    )}
                    <button
                      disabled={saving}
                      type="submit"
                      className="btn btn-primary"
                    >
                      <span className="font-size-12">
                        {saving ? "Saving..." : "Save Section"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
    </form>
  );
}

export default DrillTemplate;
