import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import ApsModels from "../../models";
import ApsServices from "../../services";
import toastStore from "../../stores/ToastStore";
import DateTimePicker from "../Common/DateTimePicker";
//import Pagination, { usePaging } from "../Common/Pagination";
import FileSaver from "file-saver";
import commonService from "../../services/CommonService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../Common/ConfirmDialog";
import "../../styles/common.scss";
import systemStore from "../../stores/SystemStore";
import { UserService } from "../../../services/UserService";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { Dropdown } from "react-bootstrap";

function Drills(
  props: RouteComponentProps<any> & { employeeId?: any; asChild?: boolean }
) {
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
  }, []);

  useEffect(() => {
    if (departmentId > 0) {
      getDrillReports();
    }
  }, [departmentId]);

  const [drillReports, setDrillReports] = useState(
    {} as ApsModels.IDrillReportGridDto
  );

  const [error413RecordLimit, setError413RecordLimit] = useState(0);
  const [has413Error, setHas413Error] = useState(false);
  const [email, setEmail] = useState("");

  const [dateFrom, setDateFrom] = useState(
    moment(moment().format("YYYY-MM-01")).toDate()
  );
  const [dateTo, setDateTo] = useState(moment().endOf("month").toDate());
  const [dateFilter, setDateFilter] = useState(
    {} as ApsModels.IDrillReportGridInputDto
  );
  const [showDates, setShowDates] = useState(false);

  //const [paging, setPaging] = usePaging(1, 20);
  // const pageChange = (page: number, pageSize: number) => {
  //   setPaging({ ...paging, page: page, pageSize: pageSize });
  // };

  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState(null as any);
  const [hasDepartment, setHasDepartment] = useState(true);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    toggleColSizes();
  };

  const getDrillReports = async () => {
    gridApi && gridApi.showLoadingOverlay();
    setHas413Error(false);

    let req: Promise<ApsModels.IDrillReportGridDto>;
    if (Number(props.employeeId)) {
      req = ApsServices.http.drillReport.getDrillReportsForUser({
        ...dateFilter,
        userId: Number(props.employeeId),
      });
    } else {
      req = ApsServices.http.drillReport.getAll(dateFilter);
    }

    await req
      .then((data) => {
        data.totalRecords = data?.drillReportGridOutputItemDtos?.length;
        setDrillReports(data);

        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
        }
      })
      .catch((err) => {
        const msg = commonService.parseErrorMessage(err);
        const rtn = commonService.parse413Error(err);
        if (rtn.limit > 0 && commonService.getErrorStatusCode(err)) {
          setError413RecordLimit(rtn.limit);
          setHas413Error(true);
          setEmail(rtn.email);
          setState({
            ...state,
            rowData: [],
          });
          setDrillReports({} as any);
          gridApi && gridApi.showNoRowsOverlay();
        } else {
          gridApi && gridApi.hideOverlay();
          toastStore.showError("Failed getting log drills", err);
          const noDeptErr = "User Department is Missing";
          if (msg.indexOf(noDeptErr) > -1) {
            setHasDepartment(false);
          }
        }
      });
  };

  // useEffect(() => {
  //   if (gridApi) {
  //     getDrillReports();
  //   }
  // }, [gridApi]); //paging,

  useEffect(() => {
    // if (dateFilter.startTimeString && dateFilter.endTimeString) {
    //   setPaging({ ...paging, page: 1 });
    // } else if (!dateFilter.startTimeString && !dateFilter.endTimeString) {
    //   setPaging({ ...paging, page: 1 });
    // }
    if (gridApi && dateFilter.endTimeString && dateFilter.startTimeString) {
      systemStore.setDrillReportFilters(dateFilter);
      getDrillReports();
    }
  }, [dateFilter, gridApi]);

  const [claims, setClaims] = useState(commonService.friendlyClaims);
  const [sendingReport, setSendingReport] = useState(false);

  const sendReport = async () => {
    setSendingReport(true);
    await ApsServices.http.drillReport
      .sendLogDrillReport(dateFilter || {})
      .then((data) => {
        setSendingReport(false);
        toastStore.showToast(
          `Your report is being generated. When it is ready, it will be sent to ${
            email ? `<strong>${email}</strong>` : "your email"
          } with a link to download the report.`,
          "info"
        );
        setHas413Error(false);
      })
      .catch((err) => {
        setSendingReport(false);
        toastStore.showError("Failed Generating Report", err);
      });
  };

  useEffect(() => {
    setClaims(commonService.friendlyClaims);

    if (
      systemStore.drillReportFilters?.startTimeString &&
      systemStore.drillReportFilters?.endTimeString
    ) {
      setDateFrom(
        moment(systemStore.drillReportFilters.startTimeString).toDate()
      );
      setDateTo(moment(systemStore.drillReportFilters.endTimeString).toDate());
      setDateFilter({
        endTimeString: systemStore.drillReportFilters.endTimeString,
        startTimeString: systemStore.drillReportFilters.startTimeString,
        isMyReportsOnly: claims.canReadAll
          ? systemStore.drillReportFilters.isMyReportsOnly
          : true,
      });
    } else {
      setDateFilter({
        startTimeString: moment(dateFrom).format("YYYY-MM-DD"),
        endTimeString: moment(dateTo).format("YYYY-MM-DD"),
        isMyReportsOnly: true,
      });
    }
    setShowDates(true);
  }, []);

  const EditButtonCellComponent = (rowProps: {
    data: ApsModels.IDrillReportGridOutputItemDto;
  }) => {
    const buttonClicked = () => {
      props.history.push(`/drills/${rowProps.data.id}`);
    };

    let cbc =
      rowProps.data?.drillStatus === ApsModels.DrillStatusEnum.Submitted
        ? rowProps.data?.canBeChanged
        : true;

    let canEdit = commonService.userInfo?.isAdmin;
    if (!canEdit && cbc) {
      canEdit =
        claims.canEditAll ||
        (claims.canEditOwn &&
          commonService.userInfo?.userId === rowProps.data?.reporterId);
      //P23172-183 - EditOwn can now only edit whay I submitted
      // ||
      // !!rowProps.data?.selectedParticipants?.find(
      //   (p) => p.id === commonService.userInfo?.userId
      // )
    }

    let canRead = commonService.userInfo?.isAdmin;
    if (!canEdit) {
      canRead =
        claims.canReadAll ||
        (claims.canReadOwn &&
          (commonService.userInfo?.userId === rowProps.data?.reporterId ||
            !!rowProps.data?.selectedParticipants?.find(
              (p) => p.id === commonService.userInfo?.userId
            )));
    }

    return (
      <>
        {canEdit && (
          <button
            type="button"
            className="btn btn-sm btn-link me-4"
            onClick={() => buttonClicked()}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
        )}
        {!canEdit && canRead && (
          <button
            type="button"
            className="btn btn-sm btn-outlined me-4"
            onClick={() => buttonClicked()}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        )}

        {canRead && (
          <button
            type="button"
            className="btn btn-sm btn-link"
            onClick={(e) => {
              props.history.push(`/drills/${rowProps.data.id}/reuse`);
            }}
            style={{
              fontWeight: "500",
            }}
          >
            Reuse
          </button>
        )}
      </>
    );
  };

  const StatusCellComponent = (rowProps: {
    data: ApsModels.IDrillReportGridOutputItemDto;
  }) => {
    let className = "alert-success";
    let label = "Completed";
    if (rowProps.data.drillStatus === ApsModels.DrillStatusEnum.Draft) {
      className = "alert-warning";
      label = "Draft";
    } else if (
      rowProps.data.drillStatus === ApsModels.DrillStatusEnum.Assigned
    ) {
      className = "alert-secondary";
      label = "Assigned";
    }

    return (
      <span
        style={{
          padding: "0 5px",
        }}
        className={`alert ${className}`}
      >
        <small>{label}</small>
      </span>
    );
  };

  const TrainingTypesComponent = (rowProps: {
    data: ApsModels.IDrillReportGridOutputItemDto;
  }) => {
    return (
      <div
        className="training-type-cell"
        id={`trainingType${rowProps?.data?.id}`}
      >
        {rowProps?.data?.trainingTypes?.map((trnType, i) => (
          <div key={i}>{trnType}</div>
        ))}
      </div>
    );
  };

  const ReuseDrillComponent = (rowProps: {
    data: ApsModels.IDrillReportGridOutputItemDto;
  }) => {
    return (
      <div
        className="pointer text-primary"
        onClick={(e) => {
          props.history.push(`/drills/${rowProps.data.id}/reuse`);
        }}
        style={{
          fontWeight: "500",
        }}
      >
        Reuse
      </div>
    );
  };

  const [state, setState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: (params: any) => {
          if ((params?.data?.participantId || 0) === 0) {
            return false;
          }
          if (!claims?.canExportAll) {
            return (
              claims?.canExportOwn &&
              params?.data?.reporterId === commonService.userInfo?.userId
            );
          }
          return true;
        },
        field: "_selected",
        headerName: "",
        width: 10,
        resizable: false,
      },
      {
        field: "id",
        headerName: "ID",
      },
      { field: "reporter", headerName: "Reporter" },
      {
        field: "fullName",
        headerName: "Participants",
        resizable: true,
        minWidth: 250,
      },
      { field: "drillName", headerName: "Drill" },
      { field: "location", headerName: "Location" },
      { field: "instructor", headerName: "Instructor" },
      {
        field: "trainingTypes",
        headerName: "Training Types",
        minWidth: 200,
        flex: 1,
        resizable: true,
        wrapText: true,
        autoHeight: true,
        cellRenderer: "trainingTypesComponent",
      },
      {
        field: "date",
        headerName: "Date",
        valueFormatter: (param: any) => {
          return moment(param.data.date).format("MMM DD, YYYY");
        },
      },
      {
        field: "hours",
        headerName: "Hours",
        valueFormatter: (param: any) => {
          if (param.data.drillStatus === ApsModels.DrillStatusEnum.Assigned) {
            return "";
          }
          return param.data.hours;
        },
      },
      { field: "calJac", headerName: "CAL-JAC" },
      { field: "isaCourse", headerName: "ISA" },
      {
        field: "drillReportEligibility",
        headerName: "Eligibility",
      },
      {
        field: "hasBeenSubmitted",
        headerName: "Status",
        cellRenderer: "statusCellComponent",
      },
      {
        field: "actions",
        headerName: "Actions",
        cellRenderer: "editButtonCellComponent",
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
    },
    frameworkComponents: {
      editButtonCellComponent: EditButtonCellComponent,
      statusCellComponent: StatusCellComponent,
      trainingTypesComponent: TrainingTypesComponent,
    },
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading Log Drills</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Drill Reports Found</span>',
    autoGroupColumnDef: { minWidth: 200 },
    rowGroupPanelShow: "always",
    rowData: [] as any[],
  });

  const getNoRowsOverlay = () => {
    if (has413Error) {
      return '<span class="ag-overlay-loading-center">Too Many Records</span>';
    } else {
      return '<span class="ag-overlay-loading-center">No Drill Reports Found</span>';
    }
  };

  const toggleColSizes = () => {
    if (gridApi && gridColumnApi) {
      setTimeout(() => {
        gridApi.sizeColumnsToFit();
        let allColumnIds: any[] = [];
        gridColumnApi.getAllColumns()?.forEach(function (column: any) {
          if (
            column.colId !== "_selected" &&
            column.colId !== "fullName"
            //&& column.colId !== "trainingTypes"
          ) {
            allColumnIds.push(column.colId);
          }
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
      }, 500);
    }
  };

  const getGridRows = () => {
    const rtn = [
      {
        ...({} as ApsModels.IDrillReportGridOutputItemDto),
        ...({} as ApsModels.IDrillReportParticipantOutputDto),
        participantId: 0,
        fullName: "",
      },
    ];
    rtn.splice(0, 1);
    drillReports?.drillReportGridOutputItemDtos?.forEach((drill) => {
      if (drill.selectedParticipants.length > 0) {
        drill.selectedParticipants.forEach((user) => {
          rtn.push({
            ...user,
            ...drill,
            participantId: user.id,
            fullName: `${user.lastName}, ${user.firstName}`,
            drillReportEligibility: user.drillReportEligibility,
          });
        });
      } else {
        rtn.push({
          ...({} as ApsModels.IDrillReportParticipantOutputDto),
          ...drill,
          participantId: 0,
          fullName: `No Participant`,
        });
      }
    });

    return rtn;
  };

  const getSelectedRows = () => {
    if (!gridApi) {
      return [];
    }

    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node);
    });
    const isAnyFilterPresent = gridApi.isAnyFilterPresent();

    const rows = (gridApi.getSelectedRows() as any[]).filter((row) => {
      return (
        row.participantId !== 0 &&
        (claims?.canExportAll ||
          (claims?.canExportOwn &&
            row?.reporterId === commonService.userInfo?.userId)) &&
        (!isAnyFilterPresent ||
          !!filteredRows.find(
            (r: any) =>
              r.data.id === row.id && r.data.participantId === row.participantId
          ))
      );
    });
    return rows;
  };

  const [exporting, setExporting] = useState(false);
  const startExport = async () => {
    const rtn = getSelectedRows();
    const dtos = [] as ApsModels.IDrillReportExtractInputItemDto[];

    rtn.forEach((p) => {
      const line = dtos.filter((d) => d.drillReportId === p.id)[0];
      if (!!line) {
        line.participants.push(p.participantId);
      } else {
        dtos.push({
          drillReportId: p.id,
          participants: [p.participantId],
        });
      }
    });

    setExporting(true);
    await ApsServices.http.drillReport
      .export({
        drillReportExtractInputItemDtos: dtos,
      })
      .then((data) => {
        setExporting(false);
        const file = commonService.b64toBlob(data.fileContents, "text/csv");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((err) => {
        setExporting(false);
        toastStore.showError("Failed exporting log drills", err);
      });
  };

  useEffect(() => {
    setState({
      ...state,
      rowData: getGridRows() || [],
    });
    toggleColSizes();
  }, [drillReports]);

  const error413 = () => {
    return `
    Your search returned more than ${error413RecordLimit.toLocaleString()} records, which is too many to display on the screen.  
    You may either select to have a report generated over email or you may click "Cancel" to refine your search parameters.
    `;
  };

  return (
    <>
      <ConfirmDialog
        show={has413Error}
        buttons={[
          {
            label: "Cancel",
            value: "cancel",
            variant: "secondary",
            disabled: sendingReport,
          },
          {
            label: sendingReport ? "Generating Report..." : "Generate Report",
            value: "report",
            variant: "warning",
            disabled: sendingReport,
          },
        ]}
        title="Too Many Records"
        message={error413()}
        done={(rtn) => {
          console.log(rtn);
          if (sendingReport) {
            return;
          }
          if (rtn === "report") {
            sendReport();
          } else {
            setHas413Error(false);
          }
        }}
      ></ConfirmDialog>
      <div
        className="flex flex-1 flex-column"
        style={props.asChild ? {} : { marginBottom: "-60px" }}
      >
        <div
          className="container-fluid flex-card-container"
          style={props.asChild ? { padding: 0 } : {}}
        >
          <div className="flex-0">
            {!!props.asChild && (
              <div className="px-4 pt-4 bg-white text-uppercase">
                <strong>Drills</strong>
              </div>
            )}
            {!props.asChild && (
              <div className="headerControls">
                <div>
                  <span className="h4 mb-0 font-size-18 text-uppercase">
                    DRILLS
                  </span>
                </div>
                <div></div>
              </div>
            )}
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div
                    className="flex flex-row mb-3"
                    style={{ flexWrap: "wrap", gap: "10px" }}
                  >
                    <div
                      className="flex-1 flex"
                      style={{
                        flexWrap: "wrap",
                        gap: "10px",
                        alignItems: "center",
                        minWidth: "250px",
                      }}
                    >
                      <div className="mr-4">
                        <button
                          disabled={
                            exporting || (drillReports?.totalRecords || 0) === 0
                          }
                          className="btn btn-outline-secondary"
                          onClick={() => {
                            const rtn = getSelectedRows();
                            if (rtn.length === 0) {
                              toastStore.showToast(
                                "No selected item(s) to export",
                                "warning"
                              );
                            } else {
                              startExport();
                            }
                          }}
                        >
                          {exporting ? "Exporting..." : "Export"}
                        </button>
                      </div>
                      <div>
                        <strong>Filter</strong>
                      </div>

                      {showDates && (
                        <>
                          <DateTimePicker
                            dateOnly={true}
                            onChange={(data) => {
                              setDateFilter({
                                ...dateFilter,
                                startTimeString: data
                                  ? moment(data).format("YYYY-MM-DD")
                                  : "",
                              });
                            }}
                            data={dateFrom}
                            dayPickerProps={{
                              disabledDays: dateFilter?.endTimeString
                                ? [
                                    {
                                      after: moment(
                                        dateFilter?.endTimeString
                                      ).toDate(),
                                    },
                                  ]
                                : [],
                            }}
                            disabled={!hasDepartment}
                          />
                          <div>
                            <strong>to</strong>
                          </div>
                          <DateTimePicker
                            dateOnly={true}
                            onChange={(data) => {
                              setDateFilter({
                                ...dateFilter,
                                endTimeString: data
                                  ? moment(data).format("YYYY-MM-DD")
                                  : "",
                              });
                            }}
                            data={dateTo}
                            dayPickerProps={{
                              disabledDays: dateFilter?.startTimeString
                                ? [
                                    {
                                      before: moment(
                                        dateFilter?.startTimeString
                                      ).toDate(),
                                    },
                                  ]
                                : [],
                            }}
                            disabled={!hasDepartment}
                          />
                        </>
                      )}

                      {claims.canReadAll && (
                        <div className="flex flex-center">
                          <strong className="pr-2">View</strong>
                          <Dropdown drop="down">
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdownDrillViewFilter"
                            >
                              {dateFilter.isMyReportsOnly
                                ? "My Drill Records"
                                : "All Drill Records"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu align="right">
                              <Dropdown.Item
                                onClick={(e) => {
                                  setDateFilter((p) => {
                                    return {
                                      ...p,
                                      isMyReportsOnly: true,
                                    };
                                  });
                                }}
                              >
                                My Drill Records
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e) => {
                                  setDateFilter((p) => {
                                    return {
                                      ...p,
                                      isMyReportsOnly: false,
                                    };
                                  });
                                }}
                              >
                                All Drill Records
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                    <div className="flex-0">
                      {claims.canSubmitOwn && (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary nowrap"
                            onClick={() => {
                              props.history.push("/drills/new");
                            }}
                            disabled={!hasDepartment}
                          >
                            Submit New Drill
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <div style={{ width: "100%", height: "100%" }}>
                    <div
                      id="myGrid"
                      style={{
                        height: "100%",
                        minHeight: "300px",
                      }}
                      className="ag-theme-alpine flex-1"
                    >
                      <AgGridReact
                        columnDefs={state.columnDefs}
                        defaultColDef={state.defaultColDef}
                        autoGroupColumnDef={state.autoGroupColumnDef}
                        enableRangeSelection={true}
                        animateRows={true}
                        onGridReady={onGridReady}
                        rowSelection={"multiple"}
                        rowMultiSelectWithClick={false}
                        suppressRowDeselection={true}
                        suppressRowClickSelection={true}
                        frameworkComponents={state.frameworkComponents}
                        rowData={state.rowData || []}
                        onColumnResized={() => {
                          gridApi && gridApi.resetRowHeights();
                        }}
                        onFirstDataRendered={() => {
                          gridApi && gridApi.resetRowHeights();
                        }}
                        getRowHeight={(params: any) => {
                          return document.getElementById(
                            `trainingType${params?.data?.id}`
                          )?.clientHeight;
                        }}
                        onRowDoubleClicked={(event) => {
                          props.history.push(`/drills/${event.data.id}`);
                        }}
                        overlayLoadingTemplate={state.overlayLoadingTemplate}
                        overlayNoRowsTemplate={getNoRowsOverlay()}
                        getRowStyle={(params: {
                          data: ApsModels.IDrillReportGridOutputItemDto;
                        }) => {
                          if (
                            ((params?.data as any)?.participantId || 0) === 0
                          ) {
                            return {
                              color: "#f46a6a",
                              backgroundColor: "transparent",
                            };
                          } else {
                            if (!claims?.canExportAll) {
                              if (
                                claims?.canExportOwn &&
                                (params?.data?.reporterId ===
                                  commonService.userInfo?.userId ||
                                  !!params?.data?.selectedParticipants?.find(
                                    (p) =>
                                      p.id === commonService.userInfo?.userId
                                  ))
                              ) {
                                return {};
                              } else {
                                return {
                                  color: "#777",
                                  backgroundColor: "transparent",
                                };
                              }
                            }
                            return {};
                          }
                        }}
                      />
                    </div>
                  </div>

                  {drillReports?.totalRecords > 0 &&
                    state.rowData?.length > 0 && (
                      <>
                        <div className="pt-3">
                          {(state.rowData?.length || 0).toLocaleString()}{" "}
                          Record(s) in{" "}
                          {(drillReports?.totalRecords || 0).toLocaleString()}{" "}
                          Drill Report(s) Found
                        </div>
                      </>
                    )}

                  {/* {
                    <Pagination
                      length={drillReports?.totalRecords}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      pageChange={pageChange}
                      showingOfWhatLabel="Log Drills"
                      disabled={!hasDepartment}
                      sizes={[
                        10, 20, 50, 100, 500, 1000, 2000, 3000, 5000, 7500,
                        10000,
                      ]}
                      totalRows={state.rowData?.length || 0}
                    ></Pagination>
                  } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drills;
